import React from 'react';
import { Flex } from '@abyss/web/ui/Flex';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { Card } from '@abyss/web/ui/Card';
import { getFeature } from '@hhs/api/src/tools/features';

import { styles } from '../LandingPage.styles';

export const Tab3 = () => {
  const classes = useStyles(styles);

  return (
    <React.Fragment>
      <Flex>
        <Flex.Content>
          <Card className={classes.tab3Card}>
            <Card.Section className={classes.cardBodyTab3}>
              <div className={classes.leadLine}>What You Need</div>
              <br />
              <h2>1. Validate Taxpayer Identification Number (TIN)</h2>
              <div>
                <p>
                  This should be the organization TIN ("Filing TIN") you will
                  use in applying for relief funds. An Organization TIN may not
                  necessarily bill Medicare or Medicaid directly. The
                  Organization TIN may have one or more subsidiaries that do not
                  file tax returns (disregarded or consolidated entities). The
                  Organization TIN should complete an application by listing all
                  of the subsidiary TINs in the applicable field within the
                  application form. TIN Validation can take 1-2 business days to
                  process.
                </p>
                <b>
                  Important! Only one person can serve as the program
                  administrator per TIN.
                </b>{' '}
                This administrator accepts responsibility to act on behalf of
                their organization and must agree to make their name available
                to others within their organization. This person may reassign or
                transfer their administrator role to a new individual in their
                organization with an{' '}
                {getFeature('OIDC_MIGRATION')
                  ? 'One Healthcare ID'
                  : 'Optum ID'}{' '}
                at any time by calling (866) 569-3522: for TTY dial 711. Hours
                of operation are 8 a.m. to 8 p.m. Central Time, Monday through
                administrator processing can take 1-2 business days to complete.
                <br />
                <br />
              </div>
              <h2>2. Confirm Revenue and Tax information</h2>
              <div>
                You will need to provide specific revenue and tax information
                through the portal once TIN Validation is complete.
              </div>
              <br />
              <h2>3. Receive and Attest to Payment</h2>
              <p>
                Within 90 days of receiving this payment, you must sign an
                attestation confirming receipt of the funds and agreeing to the
                Terms and Conditions of payment. Should you choose to reject the
                funds, you must also complete the attestation to indicate this.
                The HRSA Provider Relief Fund Payment Attestation Portal will
                guide you through the attestation process to accept or reject
                the funds. Not returning the payment within 90 days of receipt
                will be viewed as acceptance of the Terms and Conditions.
              </p>
            </Card.Section>
          </Card>
        </Flex.Content>
      </Flex>
    </React.Fragment>
  );
};
