import { event } from '@abyss/web/tools/event';
import { adobeAnalytics } from '@abyss/web/tools/event/listeners/adobeAnalytics';
import { newRelicAnalytics } from '@abyss/web/tools/event/listeners/newRelicAnalytics';
import { config } from '@abyss/web/tools/config';
import { adobeTags } from './tags/adobe';
import { newRelicTags } from './tags/newRelic';

export const registerAnalytics = data => {
  event.listener(
    adobeAnalytics({
      enabled: config('ANALYTICS_TRACKING'),
      logging: config('ANALYTICS_LOGGING'),
      events: adobeTags,
      metadata: data,
    })
  );
  event.listener(
    newRelicAnalytics({
      enabled: config('ANALYTICS_TRACKING'),
      logging: config('ANALYTICS_LOGGING'),
      events: newRelicTags,
      metadata: data,
    })
  );
};
