import React from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { Router } from '@abyss/web/ui/Router';
import { FooterLinks } from '@hhs/ui/src/common/FooterLinks';
import { styles } from './Content.styles';
import { LandingPage } from './routes/LandingPage';

const Content = () => {
  const classes = useStyles(styles)

  return (
    <main id="main-content" className={classes.content}>
      <Router.Routes title="{{title}} | COVID-19 Cares Provider Relief Fund">
        <Router.Route
          title="Terms Of Use"
          path="/terms-of-use"
          element={<FooterLinks.TermsOfUse/>}
        />
        <Router.Route
          title="Privacy Policy"
          path="/privacy-policy"
          element={<FooterLinks.PrivacyPolicy/>}
        />
        <Router.Route
          title="Accessibility Statement"
          path="/accessibility-statement"
          element={<FooterLinks.AccessibilityStatement/>}
        />
        <Router.Route
          title="Contact Us"
          path="/contact-us"
          element={<FooterLinks.ContactUs/>}
        />
        <Router.Route
          title="Communication Preferences"
          path="/communication-preferences"
          element={<FooterLinks.CommunicationPreferences/>}
        />
        <Router.Route index title="Home" element={<LandingPage/>} />
      </Router.Routes>
    </main>
  );
};

export { Content };
